export default {
  home: {
    minute: "minuto",
    hour: "hora",
    days: "días",
    price_info:
      "Los primeros {mianfei} minutos son gratuitos. El cargo máximo por 24 horas es de {currencyName}{fengding}. Su depósito de {currencyName}{yajin} será reembolsado automáticamente después de devolver el banco de energía",
    feature_1: "Incluye todos los tipos de cables: iPhone, Type-C y Micro-USB",
    feature_2: "Puede devolverse en cualquier estación",
    feature_3:
      "Carga inalámbrica disponible",
    tips_1:
      "Su depósito de {currencyName}{yajin} se devolverá al final del alquiler",
    tips_2:
      "El depósito de recarga {currencyName}{yajin} se puede utilizar para el alquiler, y cuando se agote se puede reembolsar",
    tips_3:
      "El saldo actual es {currencyName}{myyue}, que se puede usar para el alquiler directamente",
    submit: "Comenzar alquiler",
    borrow: "Alquilar",
    msg_fail_title: "Error",
    msg_fail_content: "El alquiler falló, por favor intente nuevamente",
    msg_rentcount: "Actualmente tiene {zujieNum} pedidos que no están cerrados",
    goborrow: "¡Ir a cargar!",
    nearby: "Ver estaciones cercanas",
    preauth: "Pre-autorización"
  },
  login: {
    code_title: "Verificación por SMS",
    phone_number: "Número de Teléfono",
    verification_code: "Por favor ingrese el código de verificación de SMS",
    captcha_code: "Código",
    send_code: "Enviar Código",
    login: "Iniciar Sesión",
    agree: "Al hacer clic en Iniciar Sesión, acepta ",
    readme: "Acuerdo de Usuario",
    "login_and": " y ",
    "privacy_policy": "Política de privacidad",
    "user_agreement": "Acuerdo de usuario",
    msg_fail_login: "Inicio de sesión fallido",
    msg_success_login: "Inicio de sesión exitoso",
    msg_fail_user: "Error al obtener información del usuario. ¿Desea intentarlo nuevamente?",
    sign_in_with: "Iniciar sesión con",
    no_sms: "No recibió el mensaje de texto",
    retry: "Reenviar"
  },
  confirm: {
    deletecard: "Eliminar Tarjeta",
    addcard: "Ingrese los detalles de la tarjeta",
    title: "Confirmación de Pago",
    paynow: "Pago inmediato",
    SMSConfirm:
      "Por favor, pague según el mensaje de texto recibido en su teléfono y haga clic en el botón de confirmar para continuar.",
    pay_label: "Monto",
    auth_label: "Pre-autorización",
    price_tips:
      "Su saldo puede retirarse si lo solicita. Puede tardar de 1 a 5 días hábiles en aparecer en su cuenta de pago.",
    auth_tips:
      "Se requiere un monto de pre-autorización de {0}{1}. El monto real del alquiler se deducirá cuando se devuelva el banco de energía.",
    pay_type: "Por favor seleccione su método de pago preferido",
    submit: "Confirmar pago",
    msg_loading: "Cargando...",
    msg_fail_title: "Advertencia",
    msg_fail_payment: "Error al iniciar el pago del depósito",
    msg_fail_order: "Error al crear una orden de alquiler",
    msg_fail_support: "El sistema no es compatible",
    msg_fail_confirm: "Confirmar",
    msg_fail_retry: "Reintentar",
    msg_fail_cancel: "Cancelar"
  },
  mine: {
    title: "Perfil del Usuario",
    wallet: "Mi Billetera",
    balance: "Mi Saldo",
    deposit: "Depósito",
    wallet_in: "Cargar",
    wallet_out: "Retirar",
    wallet_log: "Registro de la Billetera",
    rental_history: "Historial de Alquileres",
    payment_title: "Métodos de Pago",
    download_app: "Descargar la app",
    settings: "Configuración",
    help: "Ayuda",
    logout: "Cerrar Sesión",
    login_tips: "Por favor Inicie Sesión",
    msg_fail_title: "Error",
    msg_fail_update: "Modificación fallida. Por favor intente nuevamente",
    msg_loading: "Cargando...",
    msg_fail_user: "Error al obtener información del usuario. ¿Desea intentarlo nuevamente?",
    edit_profile: "Editar Perfil",
    join_us: "ÚNETE A NOSOTROS",
    join_us_details: "Obtén una estación FullBatt en tu local"
  },
  msg: {
    appoffline: "La aplicación aún no se ha lanzado, por favor espere",
    deletecard: "¿Está seguro de que desea eliminar esta tarjeta?",
    no_phone: "Se requiere un número de teléfono",
    loading: "Cargando...",
    title: "Sugerencia",
    success: "Operación Exitosa",
    error: "Error desconocido. Por favor intente nuevamente más tarde",
    roleerror: "Permisos insuficientes. Consulte con el administrador.",
    neterror: "Error de conexión de red. Por favor intente nuevamente más tarde",
    confirm: "Confirmar",
    retry: "Reintentar",
    cancel: "Cancelar",
    title_fail: "Advertencia",
    vieworder: "Ver orden",
    success_payment: "Pago completado",
    success_buy: "Compra completada",
    fail_payment: "Fallo en el pago",
    keyword: "Palabra clave",
    ispayorder: "Por favor confirme si el pago se ha realizado",
    unpay: "No pagado",
    yespay: "Pagado",
    notopen: "Aún no está abierto"
  },
  result: {
    tips:
      "Para ver órdenes y otras ubicaciones de estaciones, por favor descargue la aplicación.",
    download: "Descargar la app",
    title: "Alquiler confirmado",
    gotit: "Entendido",
    price_plan: "Estándar de Carga",
    msg_success: "Alquiler Exitoso",
    msg_tips_loading:
      "El banco de energía se está expulsando, por favor espere...",
    msg_result_loading: "Se está confirmando el resultado del pago...",
    msg_fail_confirm: "Confirmar",
    btn_usage: "Nota de uso",
    btn_return: "Nota de devolución",
    deposit: "Depósito",
    msg_fail_content: "Solicitud fallida. Por favor intente nuevamente.",
    price_label: "por hora",
    price_info:
      "Gratis durante los primeros {mianfei} minutos，Un cargo máximo diario de {ccurrency}{fengding}. El depósito se deducirá automáticamente cuando el alquiler alcance {ccurrency}{yajin}",
    tips_1:
      "Por favor recoja el banco de energía No.{number} como se muestra en la imagen",
    tips_2:
      "Por favor organice los cables e inserte el banco de energía en la ranura con el lado marcado con [flechas hacia abajo]",
    msg_note_usage:
      "El banco de energía está equipado con 3 tipos de cables, por favor elija el que necesita para su dispositivo.",
    msg_note_return:
      "Por favor organice los cables e inserte el banco de energía en la ranura con el lado marcado con [flechas hacia abajo]",
    return_info:
      "Al devolver, asegúrese de que los cables estén organizados e inserte el lado con chips de metal en una ranura disponible hasta que llegue al fondo y se estabilice.",
    download_tips: "Para alquilar nuestro banco de energía, puede elegir una de las siguientes opciones"
  },
  result_msg: {
    loading: "Cargando...",
    refresh: "Actualizar"
  },
  order: {
    title: "Historial de Alquileres",
    in_use: "En Uso",
    ended: "Finalizado",
    msg_error: "Solicitud fallida",
    order_number: "No.",
    order_details: "Detalles de la Orden",
    order_zj_shop: "Local alquilado",
    order_zj_time: "Hora de alquiler",
    order_gh_shop: "Local de devolución",
    order_gh_time: "Hora de devolución",
    order_use_time: "Tiempo de uso",
    order_amount: "Monto total",
    not_on_rent: "No en alquiler",
    "renting": "En alquiler",
    "undone": "Incompleto",
    "returned": "Devuelto",
    "questions": "Preguntas",
    "timeout_to_return": "Tiempo de devolución agotado",
    "suspended_for_billing": "Suspendido para facturación"
  },
  order_item: {
    order_number: "No.",
    venue_rented: "Ubicación de recogida",
    time_rented: "Hora de recogida",
    venue_returned: "Ubicación de devolución",
    time_returned: "Hora de devolución",
    charge_standard: "Estándar de cargo",
    charge_standard_info:
      "{pJifei} {pCurrency} por hora (los primeros {pMian} minutos son gratuitos)",
    rent_occurred: "Se realizó el alquiler",
    returned: "Devuelto",
    unreturned: "No devuelto",
    type_0: "No Completado",
    type_1: "En alquiler",
    type_2: "Cancelado",
    type_3: "Devuelto",
    type_4: "Error ocurrido",
    type_5: "Tiempo extra",
    min: "Min",
    payment: "Pago"
  },
  setting: {
    title: "Configuración",
    lang: "Idioma"
  },
  help: {
    title: "Preguntas Frecuentes",
    rentals: "Alquileres",
    venues: "Locales",
    payments: "Pagos"
  },
  wallet_in: {
    title: "Recarga de Saldo",
    price_label: "Monto de recarga",
    price_placeholder: "Por favor ingrese el monto de recarga",
    balance: "Saldo actual",
    price_auto: "Elegir monto de pago",
    price_tips:
      "Su saldo puede retirarse si lo solicita. Puede tardar de 1 a 5 días hábiles en aparecer en su cuenta de pago.",
    submit: "Enviar",
    loading: "Cargando...",
    paying: "Redirigiendo a la página de pago...",
    msg_title: "Consejos",
    msg_fail_order: "Error al crear la orden de recarga",
    msg_fail_payment: "Error al iniciar el pago de recarga",
    msg_fail_retry: "Reintentar"
  },
  wallet_out: {
    title: "Retiro",
    price_label: "Monto de retiro",
    price_placeholder: "Por favor ingrese el monto de retiro",
    balance: "Saldo actual",
    balance_all: "Retirar todo",
    deposit: "Depósito",
    deposit_tips:
      "Hay una orden de alquiler pendiente. El depósito de Ksh{pyajin} no es reembolsable",
    submit: "Enviar",
    apply: "Aplicar",
    confirm: "Confirmar",
    loading: "Cargando...",
    msg_fail_price: "El monto de retiro es incorrecto",
    msg_fail_balance:
      "El monto de retiro no puede exceder el saldo actual",
    msg_fail_submit: "Por favor complete toda la información requerida",
    msg_title: "Error",
    msg_success_content: "Reembolso Exitoso",
    confirmtitle: "Consejos",
    confirmcontent:
      "La billetera actual no admite retiros en efectivo. Por favor registre su información de contacto para el procesamiento manual del reembolso",
    payee_name: "Nombre del beneficiario",
    payee_bankcard: "Número de tarjeta bancaria",
    payee_phone: "Teléfono",
    cancelText: "Cancelar",
    confirmText: "Reaplicar"
  },
  map: {
    在线: "Abierto",
    离线: "Cerrado",
    title: "Estación cercana",
    result_msg: "No se encontró ninguna estación cercana",
    msg_title: "error",
    no_support: "¡Lo siento! Su navegador no admite servicios de ubicación",
    my_position: "Mi Ubicación",
    distance: "Distancia:",
    shop_time: "Horas",
    free: "Disponible para alquiler",
    return: "Espacios vacíos disponibles",
    shop_title: "Detalles del local",
    address: "Dirección",
    go_here: "Ir aquí",
    nav_help: "Ayuda",
    nav_search: "Buscar"
  },
  scanQr: {
    NotAllowedError: 'Necesita otorgar acceso a la cámara',
    NotFoundError: 'No hay cámara en este dispositivo',
    NotSupportedError: 'Se requiere un contexto de seguridad (HTTPS, localhost)',
    NotReadableError: 'La cámara está en uso',
    OverconstrainedError: 'La instalación de la cámara no es apropiada',
    StreamApiNotSupportedError: 'Este navegador no admite la API de transmisión'
  },
  payment: {
    payment_title: "Métodos de Pago",
    pay_type: "Por favor agregue los detalles de su tarjeta",
    add_payment_card: "Agregar Tarjeta de Pago",
    add_payment: "agregar pago"
  },
  shop: {
    title: "Estación cercana",
    search_station: "Buscar una estación",
    search_station_tips: "Por favor ingrese el nombre de la estación",
    shop_power_banks: "Bancos de energía",
    shop_empty_slots: "Espacios vacíos",
    shop_address: "Dirección",
    shop_business_hours: "Horario de atención",
    shop_directions_station: "Obtener direcciones a la estación",
    shop_scan_qrcode: "Código QR"
  }
};
